<template>
  <div>
    <el-page-header @back="goBack" content="课程详情"> </el-page-header>
    <div class="detailbox">
      <div class="videobox">
        <video
          :src="UrlLink"
          controls="controls"
          poster=""
          loop
          width="800"
          height="450"
          controlslist="nodownload"
        >
          您的浏览器不支持 video 标签。
        </video>
      </div>
      <!-- 留言区 -->
      <div class="msgbox">
        <div>留言板</div>
        <div class="msgSection" id="msgid">
          <div class="msg-body">
            <div class="msgitem" v-for="t in msgList" :key="t.Id">
            <div class="msg">{{t.Content}}</div>
            <div class="auther">
              {{ t.AccountName }} 发表于 {{ t.CreateTime | formatTime }}
            </div>
          </div>
          </div>
        </div>
        <div class="msgInput">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="请输入留言"
            v-model="msgContent"
            @keyup.enter.native="onSend"
              clearable
          >
          </el-input>
          <div class="btns">
            <el-button
              class="sendbtn"
              type="primary"
              @click="onSend"
              >发送</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetQCVideoMessage, SaveQCVideoMessage } from "@/api";
import { nextTick } from 'vue'

export default {
  name: "VideoLesson",
  data() {
    return {
      Id: this.$route.params.Id || "",
      msgContent: "",
      msgList: [],
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async SaveQCVideoMessageAsync() {
      const r = await SaveQCVideoMessage({
        QCVideoInfoId: this.Id,
        Content: this.msgContent,
      });
      if (r.code == 1) {
        this.msgContent = "";
        this.msgList.push(r.data);
        // nextTick() 可以在状态改变后立即使用，以等待 DOM 更新完成。你可以传递一个回调函数作为参数，或者 await 返回的 Promise。
        await nextTick()
        // 页面更新后 控制滚动位置
        const msg = document.getElementById('msgid')
        msg.scrollTo(0, msg.scrollHeight)
      } else {
        this.$message.error(r.error.Message);
      }
    },
    async GetQCVideoMessageAsync() {
      const r = await GetQCVideoMessage({
        id: this.Id,
      });
      if (r.code == 1) {
        this.msgList = r.data;
      } else {
        this.$message.error(r.error.Message);
      }
    },
    onSend() {
      this.SaveQCVideoMessageAsync();
    },
  },
  computed: {
    UrlLink() {
      if (sessionStorage.videoccLink) {
        const url = sessionStorage.videoccLink;
        return url;
      } else {
        return "";
      }
    },
  },
  filters: {
    formatTime(val) {
      const str = val.replace("T", " ");
      return str;
    },
  },
  created() {
    this.GetQCVideoMessageAsync();
  },
};
</script>

<style scoped>
.detailbox {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.videobox {
  padding: 20px;
}
.msgbox {
  padding: 20px;
  width: 800px;
  background-color: #fff;
  height: calc(100vh - 180px);
  position: relative;
  margin-left: 20px;
}
.msgSection {
  padding: 8px;
  height: calc(100% - 80px);
  overflow-y: scroll;
  /* background-color: blue; */
  box-sizing: border-box;
  position: relative;
  margin-top: 10px;
}
.msgitem {
  min-height: 60px;
  margin-bottom: 40px;
}
.msg {
  padding: 10px;
  border-radius: 5px;
  background-color: #E8F1F9;
  font-size: 14px;
  min-height: 50px;
  box-sizing: border-box;
}
.auther {
  text-align: right;
  margin-top: 8px;
  font-size: 12px;
  color: #606266;
}
.msgInput {
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 5px;
  width: calc(100% - 15px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.sendbtn {
  height: 54px;
  margin-left: 5px;
}
</style>